import React from "react";
// import ServicePickUp from './servicePickup';
// import ServiceDelivery from './serviceDelivery';
// import ShippingItem from "./shippingItem";
// import FreightClass from "./freightClass";
// import QuoteSummary from "./quoteSummary";
// import {addPickUpDetails} from '../../actions/shipmentAction';
// import { addPickupDetails, loadShippingItems } from "../../slices";

// import DateFnsUtils from "@date-io/date-fns";
// import { format } from "date-fns";
// // import {dateFormat} from 'dateformat';
// import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
// import enGBLocale from "date-fns/locale/en-GB";
// import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
// import { IconButton, TextField } from "@material-ui/core";
// import { ArrowForwardIos } from "@material-ui/icons";
import { Box, TextField } from "@material-ui/core";
// import { makeStyles } from "@material-ui/core/styles";
// import { Alert } from "@material-ui/lab";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import "../../styles/shipping.css";
// import PropTypes from 'prop-types';
// import produce from "immer";
// import { AddressInputCombo } from "../shared";

// const defaultPickUpDetails = {
//   selectedDate: new Date(),
//   deliver: {
//     address: "",
//     aptInfo: "",
//     zipCode: "",
//     city: "",
//     province: "",
//   },
//   pickUp: {
//     address: "",
//     aptInfo: "",
//     zipCode: "",
//     city: "",
//     province: "",
//   },
//   locationType: "commercial",
// };

const StartQuote = () => {
  const matches = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  // const [selectedDate, setSelectedDate] = useState(new Date());

  // Form data to store all the information
  // const [formatData, setFormatData] = useState(defaultPickUpDetails);
  // const [tab, setTab] = useState("start-a-quote");

  // const [destination, setDestination] = useState('');
  // const [details, setDetails] = useState(shippingItems.pickUpDetail);
  // const [details, setDetails] = useState('');
  // const [orderNum, setOrderNum] = useState('');
  // const classes = useStyles();

  // const [showErroMsg, setMsgStatus] = useState(false);

  // const getEmptyStatus = (_formatData) => {
  //   const { pickUp, deliver } = _formatData;
  //   const pickUpEmpty =
  //     pickUp.zipCode.trim() === "" ||
  //     pickUp.address.trim() === "" ||
  //     pickUp.city.trim() === "" ||
  //     pickUp.province.trim() === "";
  //   const deliverEmpty =
  //     deliver.zipCode.trim() === "" ||
  //     deliver.address.trim() === "" ||
  //     deliver.city.trim() === "" ||
  //     deliver.province.trim() === "";
  //   return [pickUpEmpty, deliverEmpty];
  // };

  // useEffect(() => {
  //   const prevItems = localStorage.getItem("shippingData");
  //   if (prevItems) {
  //     const { pickUpDetails, shippingItems } = JSON.parse(prevItems);
  //     // setFormatData({...pickUpDetails, selectedDate: new Date(pickUpDetails.selectedDate)});
  //     setFormatData(
  //       produce((draft) => {
  //         draft = pickUpDetails;
  //         draft.selectedDate = new Date(pickUpDetails.selectedDate);
  //         draft.pickUp.province = provinces.find(
  //           (ele) => ele.value === pickUpDetails.pickUp.province
  //         ).label;
  //         draft.deliver.province = provinces.find(
  //           (ele) => ele.value === pickUpDetails.deliver.province
  //         ).label;
  //       })
  //     );
  //     dispatch(loadShippingItems({ items: shippingItems }));
  //   }
  // }, [dispatch]);

  // useEffect(() => {
  //   const [pickUpEmpty, deliverEmpty] = getEmptyStatus(formatData);
  //   if (!pickUpEmpty && !deliverEmpty) setMsgStatus(false);
  // }, [formatData]);

  // /**
  //  * @param {boolean} address Belongs to the address submodule if True
  //  * @param {string} fieldName The field name of the form data object
  //  * @param {string} addressType 'pickUp' or 'Deliver'
  //  */
  // const handleChange =
  //   (address, addressType = "pickUp") =>
  //   (fieldName) =>
  //   (e) => {
  //     setFormatData(
  //       produce((draft) => {
  //         if (address) draft[addressType][fieldName] = e.target.value;
  //         else draft[fieldName] = e.target.value;
  //       })
  //     );
  //   };

  // /**
  //  * @param {boolean} address Belongs to the address submodule if True
  //  * @param {string} fieldName The field name of the form data object
  //  * @param {string} addressType 'pickUp' or 'Deliver'
  //  */
  // const handleChangeFromSelection =
  //   (address, addressType = "pickUp") =>
  //   (fieldName) =>
  //   (newValue) => {
  //     setFormatData(
  //       produce((draft) => {
  //         if (address && newValue) draft[addressType][fieldName] = newValue;
  //         else if (address && !newValue) draft[addressType][fieldName] = "";
  //         else draft[fieldName] = newValue;
  //       })
  //     );
  //   };

  // useEffect(()=>{
  //   console.log(formatData)
  // }, [formatData])

  /*Function that handles the rendering of different components, Can be implmented as tabbed design for later*/
  const handleSelection = () => {
    // if (tab === "start-a-quote") {
    return (
      <Box
        display="flex"
        justifyContent="center"
        heigh="100%"
        flexDirection={matches ? "column" : "row"}
        m="0 3vw"
      >
        {/* <Fade duration={2000}> */}
        <Box
          display="flex"
          flexDirection="column"
          width={matches ? "100%" : "40vw"}
          mr="1vw"
        >
          <Box color="orange">
            <h1>When and Where are you shipping?</h1>
          </Box>

          <TextField
            InputProps={{ disableUnderline: true }}
            variant="filled"
            rows={2}
            label="Text here"
            multiline
          />
        </Box>
        {/* </Fade> */}
      </Box>
    );
    // }
    // if (tab === "shipping-item") {
    //   return (
    //     <ShippingItem
    //       backToThird={() => setTab("start-a-quote")}
    //       toFifth={() => {}}
    //     />
    //   );
    // }
  };

  //when the arrow button to the next page is pressed, replace the Redux pickUpDetails with the current textfiel values
  // const next = () => {
  //   // const [pickUpEmpty, deliverEmpty] = getEmptyStatus(formatData);
  //   // if (pickUpEmpty || deliverEmpty) {
  //   //   setMsgStatus(true);
  //   // } else {
  //   //   const newFormatData = {
  //   //     ...formatData,
  //   //     pickUp: {
  //   //       ...formatData.pickUp,
  //   //       province: provinces.find(
  //   //         (ele) => ele.label === formatData.pickUp.province
  //   //       ).value,
  //   //     },
  //   //     deliver: {
  //   //       ...formatData.deliver,
  //   //       province: provinces.find(
  //   //         (ele) => ele.label === formatData.deliver.province
  //   //       ).value,
  //   //     },
  //   //   };
  //   //   dispatch(addPickupDetails({ newPickUpInfo: newFormatData }));
  //   //   // setSection(4)
  //   //   history.push("/shipping/shipping-item");
  //   // }
  // };

  return (
    <div>
      {/* <Fade duration={2000}> */}
      <br />
      {handleSelection()}
      {/* </Fade> */}
    </div>
  );
};

// const theme = createMuiTheme({
//   palette: {
//     primary: { light: orange[300], main: orange[500], dark: orange[700] },
//   },
// });

// const useStyles = makeStyles((theme) => ({
//   modal: {
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//   },
//   paper: {
//     backgroundColor: theme.palette.background.paper,
//     borderRadius: "20px",
//     padding: theme.spacing(2, 4, 3),
//     overflow: "hidden",
//     width: "100%",
//   },
//   button: {
//     borderRadius: 20,
//     fontSize: "14pt",
//   },
//   input: {
//     color: "grey",
//     width: "90%",
//   },
//   largeIcon: {
//     fontSize: "200%",
//   },
//   label: {
//     flexDirection: "column",
//   },
//   icon: {
//     fontSize: "32px !important",
//     marginBottom: theme.spacing(1),
//   },
//   subTitle: {
//     color: "darkgreen",
//     fontWeight: 600,
//     fontSize: "1.5rem",
//     fontFamily: "Montserrat",
//   },
//   alertRoot: {
//     width: "80%",
//     minWidth: "200px",
//     margin: "10px auto",
//   },
// }));

// export default connect(mapStateToProps, {addPickUpDetails})(StartQuote);
export default StartQuote;
